<template>
  <div>
    <app-bar @click:drawer="drawer = !drawer"></app-bar>
    <app-drawer v-model="drawer"></app-drawer>
    <notification-drawer style="max-height: 100vh"></notification-drawer>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </div>
</template>
<script>
import AppBar from "../components/Layout/AppBar";
import AppDrawer from "../components/Layout/AppDrawer";
import NotificationDrawer from "../components/Layout/NotificationDrawer.vue";
export default {
  components: { AppBar, AppDrawer, NotificationDrawer },
  data() {
    return { drawer: false };
  },
};
</script>