<template>
  <v-navigation-drawer
    v-model="isNotificationDrawerOpen"
    app
    clipped
    temporary
    right
    :height="'100vh'"
    width="500px"
    fixed
  >
    <v-list three-line>
      <template v-for="notification in notifications">
        <v-list-item :key="notification.id">
          <v-list-item-action>
            <v-btn icon @click="clearNotification(notification)"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="text-wrap">
              {{ notification.message }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ $moment(notification.createdAt).format("LL") }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider :key="notification.publicId"></v-divider>
      </template>
    </v-list>
    <v-btn block text @click="clearNotification(null)">Clear All</v-btn>
  </v-navigation-drawer>
</template>
<script>
import * as types from "../../store/mutation-types";
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    isNotificationDrawerOpen: {
      get() {
        return this.$store.getters["notification/isNotificationDrawerOpen"];
      },
      set(val) {
        this.$store.commit("notification/" + types.TOGGLE_NOTIFICATION_DRAWER, {
          drawerState: val,
        });
      },
    },
    notifications() {
      return this.$store.getters["profile/unreadNotifications"];
    },
  },
  watch: {},
  methods: {
    clearNotification(item) {
      var ids = [];
      if (item == null) {
        ids = this.notifications.map((x) => x.id);
      } else {
        ids.push(item.id);
      }
      this.$store.dispatch("profile/markNotificationAsRead", ids);
    },
  },
};
</script>