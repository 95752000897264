<template>
  <v-navigation-drawer
    v-model="isDrawerOpen"
    :permanent="!$vuetify.breakpoint.mobile"
    app
    clipped
    v-if="showSidebar"
    :style="drawerBackgroundColor"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">{{ $route.name }}</v-list-item-title>
        <!-- <v-list-item-subtitle> subtext </v-list-item-subtitle> -->
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>
    <router-view name="sidebar"></router-view>
  </v-navigation-drawer>
</template>
<script>
export default {
  props: { value: { type: Boolean, default: false } },
  data() {
    return {
      //isDrawerOpen: this.value,
    };
  },
  computed: {
    drawerBackgroundColor() {
      return this.$vuetify.theme.dark ? { background: "#4F6272" } : null;
    },
    isDrawerOpen: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    showSidebar() {
      var matchedRoutes = this.$route.matched;
      var sidebarMeta = matchedRoutes
        .map((x) => x.meta.sidebar)
        .filter((x) => x != null);

      var showSidebar = sidebarMeta.some((x) => x); // this.$route.meta.sidebar;
      return showSidebar;
    },
  },
};
</script>