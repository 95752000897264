<template>
  <v-app-bar
    color="primary"
    tile
    dense
    dark
    app
    elevation="0"
    clipped-left
    fluid
  >
    <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

    <v-toolbar-title
      class="mr-12 pointer hidden-sm-and-down"
      @click="navigateHome()"
    >
      Cloudshelf
    </v-toolbar-title>
    <!-- <v-text-field
      :disabled="true"
      hide-details
      tile
      type="search"
      dense
      clearable
      :placeholder="$t('appbar.searchField.title')"
      prepend-inner-icon="mdi-magnify"
      single-line
      filled
      class="mx-5"
    ></v-text-field> -->

    <v-toolbar-items>
      <template v-if="$vuetify.breakpoint.mobile && showSidebar">
        <v-btn icon @click="$emit('click:drawer')"
          ><v-icon>mdi-menu</v-icon></v-btn
        >
        <v-spacer></v-spacer>
      </template>
      <v-btn text :to="{ name: toLibrary }" exact>
        <span>{{ $t("appbar.links.library") }}</span>
      </v-btn>
      <v-btn text :to="{ name: toUserList }">
        <span>{{ $t("appbar.links.usersList") }}</span>
      </v-btn>
    </v-toolbar-items>

    <v-spacer></v-spacer>

    <!-- <v-btn icon @click="openSearch">
      <v-icon>mdi-binoculars</v-icon>
    </v-btn> -->
    <v-btn icon v-if="isUserSudo && !$vuetify.breakpoint.mobile" @click="navigateToConfig">
      <v-icon>mdi-wrench</v-icon>
    </v-btn>

    <v-btn
      icon
      @click.prevent="openNotificationDrawer"
      :disabled="unreadNotificationsCount == 0"
    >
      <v-badge
        :content="unreadNotificationsCount"
        :value="unreadNotificationsCount"
        color="error"
        overlap
      >
        <v-icon>mdi-bell-outline</v-icon>
      </v-badge>
    </v-btn>
    <!-- <v-menu
      left
      offset-x
      offset-y
      nudge-left="140"
      bottom
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
      </template> -->

    <appbar-profile></appbar-profile>
    <!-- </v-menu> -->
  </v-app-bar>
</template>
<script>
import AppBarProfile from "./AppBarProfile";
import * as Routes from "../../router/route-names";
import roleMixin from "../../mixins/role";
import * as types from "../../store/mutation-types";
export default {
  components: { "appbar-profile": AppBarProfile },
  mixins: [roleMixin],
  data() {
    return {
      appProfileOpen: null,
    };
  },
  computed: {
    showSidebar() {
      var matchedRoutes = this.$route.matched;
      var sidebarMeta = matchedRoutes
        .map((x) => x.meta.sidebar)
        .filter((x) => x != null);

      var showSidebar = sidebarMeta.some((x) => x); // this.$route.meta.sidebar;
      return showSidebar;
    },
    toConfigurationPage() {
      return Routes.CONFIGURATION_PAGE;
    },
    toLibrary() {
      return Routes.LIBRARY;
    },
    toUserList() {
      return Routes.USER_LIST;
    },
    unreadNotifications() {
      return this.$store.getters["profile/unreadNotifications"];
    },
    unreadNotificationsCount() {
      return this.unreadNotifications.length;
    },
  },
  watch: {
    appProfileOpen(val) {
      console.log(val);
    },
  },
  methods: {
    navigateHome() {
      this.$router.push({ name: Routes.INDEX }).catch((e) => {});
    },
    navigateToConfig() {
      this.$router.push({ name: Routes.CONFIGURATION_PAGE }).catch((e) => {});
    },
    openNotificationDrawer() {
      this.$store.commit("notification/" + types.TOGGLE_NOTIFICATION_DRAWER, {
        drawerState: true,
      });
    },
  },
};
</script>