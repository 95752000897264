<template>
  <v-menu
    v-model="isMenuOpen"
    left
    offset-x
    offset-y
    nudge-left="140"
    bottom
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-list>
        <v-list-item class="pointer" @click="toProfile()">
          <v-list-item-avatar>
            <img
              src="https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-image-182145777.jpg"
              alt="John"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ username }}</v-list-item-title>

            <!-- <v-list-item-subtitle>{{ profile }}</v-list-item-subtitle> -->
          </v-list-item-content>

          <!-- <v-list-item-action>
          <v-btn :class="fav ? 'red--text' : ''" icon @click="fav = !fav">
            <v-icon>mdi-edit</v-icon>
          </v-btn>
        </v-list-item-action> -->
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item>
          <v-list-item-action>
            <v-switch v-model="isVuetifyDarkTheme"></v-switch>
            <!-- <v-switch v-model="$vuetify.theme.dark" color="purple"></v-switch> -->
          </v-list-item-action>
          <v-list-item-title>{{
            $t("appbar.enableTheme", { theme: currentTheme })
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-btn text block disabled>{{
            $t("appbar.links.myFavorites")
          }}</v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn text block @click="toContributions">{{
            $t("page.profile.sidebar.myContributions")
          }}</v-btn>
        </v-list-item>
        <template v-if="$vuetify.breakpoint.mobile && isUserSudo">
          <v-divider></v-divider>
          <v-list-item>
            <v-btn text block @click="toControlPanel">{{
              $t("appbar.links.configurations")
            }}</v-btn>
          </v-list-item>
        </template>
      </v-list>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          text
          @click="
            menu = false;
            logout();
          "
          >{{ $t("appbar.logout") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import * as Routes from "../../router/route-names";
import roleMixin from "../../mixins/role";
export default {
  components: {},
  mixins: [roleMixin],
  data: () => ({
    isMenuOpen: null,
  }),
  computed: {
    profile() {
      return this.$store.getters["profile/profile"];
    },
    currentTheme() {
      return this.$vuetify.theme.dark ? "light" : "dark";
    },
    username() {
      var profile = this.profile;
      var username = profile.username;
      if (username == null || username.trim().length == 0) {
        username = profile.email;
      }

      if (username == null || username.trim().length == 0) {
        username = "Anonymous";
      }

      return username;
    },
    isVuetifyDarkTheme: {
      get() {
        return this.$vuetify.theme.dark;
      },
      set(val) {
        this.updateUserTheme(val);
      },
    },
  },
  watch: {
    // isVuetifyDarkTheme(val) {
    //   console.log("is dark mode " + val);
    // },
  },
  methods: {
    updateUserTheme(isDarkMode) {
      this.$store.dispatch("profile/updateUITheme", {
        theme: isDarkMode ? "dark" : "light",
      });
    },
    toContributions() {
      this.isMenuOpen = false;
      this.$router.push({ name: Routes.PROFILE_CONTRIBUTIONS }).catch(() => {});
    },
    toControlPanel() {
      this.isMenuOpen = false;
      this.$router.push({ name: Routes.CONFIGURATION_PAGE });
    },
    toProfile() {
      this.isMenuOpen = false;
      this.$store.dispatch("navigateTo", { routeName: Routes.PROFILE });
    },
    logout() {
      this.$store.dispatch("auth/logout");
    },
  },
};
</script>